import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Users from './Users/users';
import Images from './Images/images';
import './App.css';
import Login from "./Login/login";
import ImageList from './Images/imageList';

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/images" element={<Images />} />
            <Route exact path="/images/images-list" element={<ImageList />} />
          </Routes>
        </Router>
      </div>
    );
  }
}
