import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import DatePicker from "react-datepicker";
import Navigation from "../nav";
import axios from 'axios';
import { BASE_URL, request } from "../api/request";
import "./users.scss";
import "react-datepicker/dist/react-datepicker.css";

const Users = () => {
    const [users, setUsers] = useState(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [premiumusers, setPremiumUsers] = useState(true);
    const [toDate, setToDate] = useState("")
    const [fromDate, setFromDate] = useState("");
    const [totalPremiumUsers, setTotalPremiumUsers] = useState(null)
    const [totalusers, setTotalUsers] = useState(null);
    const [totalPremYday, setTotalPremiumYday] = useState(null)
    const [totalNonPremYday, serTotalNonPremYday] = useState(null)
    const [openModal, seOpenModal] = useState(false);
    const [contactToPremium, setContactToPremium] = useState("")
    const token = localStorage.getItem("token");

    const getAllUsers = async () => {

        const fromDateparsed = fromDate ? new Date(Date.parse(fromDate)).toLocaleDateString().replaceAll("/", "_") : "";
        const toDateparsed = toDate ? new Date(Date.parse(toDate)).toLocaleDateString().replaceAll("/", "_") : "";
        try {
            const options = {
                method: 'GET',

            };
            const response = token && await request(`/api/user/getRegisteredAndPremiumUserReport?from=${fromDateparsed}&to=${toDateparsed}`, options, token);
            return response;
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        async function fetchData() {
            let allUsers = await getAllUsers();

            const nonPrem = allUsers?.data.totalYdayRegisteredUser - allUsers?.data.totalYdayPremiumUser;
            setTotalUsers(allUsers?.data.totalRegisteredUser)
            setTotalPremiumUsers(allUsers?.data.totalPremiumUser)
            setTotalPremiumYday(allUsers?.data.totalYdayPremiumUser)
            serTotalNonPremYday(nonPrem)

            if (allUsers) {
                if (premiumusers) {
                    const users = allUsers?.data.registeredUserDetails.filter((user) => user.premium == true);
                    setUsers(users)
                }

                else {
                    const users = allUsers?.data.registeredUserDetails.filter((user) => user.premium == false);
                    setUsers(users)
                }
            }
        }
        fetchData();
    }, [premiumusers]);

    const handlePremium = () => {
        setDropdownOpen(!isDropdownOpen)
    };

    const handlePremiumData = () => {
        setPremiumUsers(true)
    };

    const handleNonPremiumData = () => {
        setPremiumUsers(false)
    };

    const handleFromDateChange = (date) => {
        setFromDate(date)
    }

    const handleToDateChange = (date) => {
        setToDate(date)
    }

    const handleSearch = async () => {
        let allUsers = await getAllUsers();

        if (allUsers) {
            if (premiumusers) {
                const users = allUsers?.data.registeredUserDetails.filter((user) => user.premium == 'YES');
                setUsers(users)
            }
            else {
                const users = allUsers?.data.registeredUserDetails.filter((user) => user.premium == 'NO');
                setUsers(users)
            }
        }
    }

    const handleDownloadReport = async () => {

        const authToken = token || localStorage.getItem("token");

        const headers = {
            'Content-Type': 'blob',
            'Authorization': `Bearer ${authToken}`,
        };
        const config = {
            method: 'GET',
            url: `${BASE_URL}/api/user/generateUserReport/downloadExcel?isPremiumReport=${premiumusers}`,
            responseType: 'arraybuffer',
            headers
        };

        try {
            const response = await axios(config);
            const outputFilename = `UserReport_${Date.now()}.xls`;
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            throw Error(error);
        }
    }

    const handleMakeUserPremium = async (contactNumber) => {
        seOpenModal(true)
        setContactToPremium(contactNumber)
    }

    const toggle = () => {
        seOpenModal(!openModal);
    }

    const sure = async() => {
        try {
            const options = {
                method: 'PUT',
                body: JSON.stringify({
                    "payment": 999.0,
                    "expirationTime": "OneYear",
                    "userNumber": contactToPremium
                })

            };
            const response = token && await request(`/api/user/updateUserPremiumByNumber`, options, token);

            return response;
        } catch (error) {
            throw error;
        }
    }

    const nonPremiumUsers = totalusers - totalPremiumUsers;

    return (
        <div>
            <Navigation />

            <h1 className='users__header'>Registered User Details</h1>

            <div className='users__download' onClick={handleDownloadReport}>
                Download Report
            </div>

            <div className='users__filters'>
                <div className='users__filters__premium'>
                    <div>Filter by Premium Users:</div>
                    <Dropdown isOpen={isDropdownOpen} toggle={handlePremium}>
                        <DropdownToggle caret className='users__filters__premium__dropdown__toggle'>
                            Premium
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={handlePremiumData}>Yes</DropdownItem>
                            <DropdownItem onClick={handleNonPremiumData}>No</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className='users__filters__date'>
                    <div className='users__filters__date__header'>
                        Filter by Date
                    </div>
                    <div className='users__filters__date__header'>
                        From:
                        <DatePicker
                            selected={fromDate}
                            onChange={handleFromDateChange}
                            dateFormat='dd/MM/yyyy'
                        />
                    </div>
                    <div className='users__filters__date__header'>
                        To:
                        <DatePicker
                            selected={toDate}
                            onChange={handleToDateChange}
                            dateFormat='dd/MM/yyyy'
                        />
                    </div>
                    <div className='users__filters__date__header'>
                        <Button onClick={handleSearch} >Search</Button>
                    </div>
                </div>
            </div>
            <div className="users__count">
                <div>
                    Total Premium Users:{totalPremiumUsers}
                </div>
                <div>
                    Total Non-Premium Users: {nonPremiumUsers}
                </div>
                <div>
                    Total of Yesterday's Premium: {totalPremYday}
                </div>
                <div>
                    Total of Yesterday's Non-Premium: {totalNonPremYday}
                </div>
            </div>
            <div className='users__table'>
                {users ? <Table bordered hover responsive border="1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Registration date</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Contact Number</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Postal Code</th>
                            <th>Premium</th>
                            <th>Transaction Id</th>
                            <th>Renewal Date</th>
                            <th>premiumPrice</th>
                            <th>Distributor Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user, index) => (
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{user.registeredOn}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.number}</td>
                                <td>{user.city}</td>
                                <td>{user.state}</td>
                                <td>{user.postelCode}</td>
                             
                                <td id={`id${index}`}>
                                    {!user.premium ? <Input
                                        addon
                                        type="checkbox"
                                        disabled={user.premium}
                                        onClick={() => handleMakeUserPremium(user.number)}
                                    />: "Yes"}
                                </td>
                                <td>{user.transactionId}</td>
                                <td>{user.expiredOn}</td>
                                <td>{user.premiumPrice}</td>
                                <td>{user.distributorId}</td>
                                {user?.premium &&
                                    <UncontrolledTooltip placement="top" target={`id${index}`}>
                                        Premium date: {user.premiumOn}
                                    </UncontrolledTooltip>
                                }
                            </tr>
                        ))}
                    </tbody>

                </Table> : <Spinner />}
                <Modal isOpen={openModal} toggle={toggle}>
                <ModalHeader>Make User Premium</ModalHeader>
                <ModalBody>Are you sure?</ModalBody>
                <ModalFooter>
                    <Button onClick={toggle}>No</Button>
                    <Button onClick={sure}>Yes</Button>
                </ModalFooter>
            </Modal>
            </div>
         
        </div>
    );
};
export default Users;
