import { useState } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import "./login.scss";
import { request } from '../api/request';

const Login = () => {
    const navigate = useNavigate();

    const [username, setUserName] = useState(null);
    const [password, setPassword] = useState(null);

    const handleUsername = (e) => {
        setUserName(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const credentials = {
        username: username,
        password: password
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const options = {
                method: 'POST',
                body: JSON.stringify(credentials)
            };
            const response = await request(`/api/auth/signin`, options);

            if (response) {
                localStorage.setItem("token", response.data.token)
                navigate("/users");
            }
        } catch (error) {
            alert(error)
            throw error;
        }
    }

    return (
        <div className="login">
            <div>
                <Label className='header'>Login</Label>
                <Form className='login__form' onSubmit={handleSubmit}>
                    <FormGroup className='login__form__group'>
                        <div className='login__form__group__name'>
                            <Label for="exampleEmail">Username</Label>
                        </div>
                        <div>
                            <Input name="username" id="username" placeholder="Username" onChange={handleUsername} />
                        </div>
                    </FormGroup>
                    <FormGroup className='login__form__group'>
                        <div className='login__form__group__name'>
                            <Label for="password" >Password</Label>
                        </div>
                        <div>
                            <Input type="password" name="password" id="password" placeholder="Password" onChange={handlePassword} />
                        </div>
                    </FormGroup>
                    <Button type='submit' color='primary'>Submit</Button>
                </Form>
            </div>
        </div>
    )
}
export default Login;