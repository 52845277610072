import {
    Navbar,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
} from 'reactstrap';
import "./nav.scss";
import iioelogo from "./iioeLogo.png";

const Navigation = () => {
    return (
        <Navbar className='main-nav'>
                <NavbarBrand href="/" className='main-nav__brand'>
                    <img src={iioelogo} height={"50px"} width={"50px"} />
                </NavbarBrand>
            <div className='main-nav__div'>
                <Nav className='main-nav__items'>
                    <NavItem >
                        <NavLink className='main-nav__item' href="/users">Users</NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink className='main-nav__item' href="/images">
                            Manage Images
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        </Navbar>
    );
}

export default Navigation;