import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Compressor from 'compressorjs';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fileUploadRequest, request } from '../api/request';
import Navigation from '../nav';
import TrashIcon from "./trashIcon.svg";

import { BASE_URL } from '../api/request';
import { PlanFlyers } from './planFlyers';

import {
    generateVideoThumbnails,
    importFileandPreview
} from "@rajesh896/video-thumbnails-generator";
import axios from 'axios';


const ImageList = () => {
    const { state } = useLocation();
    const [imageList, setImageList] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [deleteImageUrl, setDeleteImageUrl] = useState('')
    const [alert, setAlert] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [success, setSuccess] = useState(false);
    const [uploadPending, setUploadPending] = useState(false);
    const [thumbail, setThumbnail] = useState(null);
    const [languageList, setLanguageList] = useState(null);
    const [selectedLan, setSelectedLan] = useState('English');
    const [planCircularFile, setPlanCircularFile] = useState(null);

    const token = localStorage.getItem("token");
    const isVideo = state.isVideo;

    const baseUrl = BASE_URL;

    const getImages = async () => {
        try {
            const options = {
                method: 'GET'
            };
    
            let response;
    
            if (state.ref === "LICPlanCircular") {
                // Use the new API endpoint for PDFs
                response = state && token && await request(`/api/pass/image/pdf/getPdfURLsByReference?reference=${state.ref}&language=${selectedLan}`, options, token);
            } else {
                // Use the original API endpoint for other references
                response = state && token && await request(`/api/pass/image/getImageURLsByReference?reference=${state.ref}&language=${selectedLan}`, options, token);
            }
    
            return response;
        } catch (error) {
            throw error;
        }
    }
    

    const getVideos = async () => {
        try {
            const options = {
                method: 'GET'
            };
            const response = state && token && await request(`/api/pass/video/getVideoURLsByReference?reference=${state.ref}`, options, token);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getLanguages = async () => {
        try {
            const options = {
                method: 'GET'
            };
            const response = state && token && await request("/api/image/getLanguageList", options, token);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const handleChange = async (e) => {
        const file = e.target.files[0] || e.dataTransfer.files[0];

    if (file && state.ref === "LICPlanCircular") {
        setPlanCircularFile(file);
    } else if (file && !isVideo) {
        setSelectedImage(file);
    } else {
            // Handle other cases, e.g., video thumbnails
            setSelectedImage(file);
    
            await generateVideoThumbnails(file, 5).then(async (thumbnail) => {
                console.log(thumbnail[4]);
                const string64 = thumbnail[4].split(",");
                const thumb = "jpeg;" + string64[1];
    
                setThumbnail(thumb.replaceAll("[\r\n]+", ""));
            }).catch((err) => {
                console.error("Error:", err);
            });
        }
    };

    const handlePlanCircularUpload = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        planCircularFile && setUploadPending(true);
    
        formData.append(
            "file",
            planCircularFile,
            planCircularFile.name
        );
    
        try {
            const options = {
                method: 'POST',
                body: formData
            };
    
            const response = await fileUploadRequest(`/api/image/pdf/uploadPdf?reference=LICPlanCircular&language=English`, options);
    
            console.log('Response status:', response.status);
            console.log('Response message:', response.message);
            console.log('Response data:', response.data);
    
            if (response.status === 200 && response.data?.responseType === "Success") {
                setSuccess(true);
                setUploadPending(false);
            } else {
                console.error('Error during PlanCircular upload. Response:', response);
            }
    
            return response;
        } catch (error) {
            console.error('Error during PlanCircular upload:', error);
            setUploadPending(false);
            throw error;
        }
    };
               
    const handleImageUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        selectedImage &&
            setUploadPending(true);

        formData.append(
            "file",
            selectedImage,
            selectedImage.name
        );

        try {
            const options = {
                method: 'POST',
                body: formData
            };
            const response = await fileUploadRequest(`/api/image/upload?reference=${state.ref}&language=${selectedLan}`, options);
            if (response.responseType == "Success") {
                setSuccess(true)
                setUploadPending(false)
            }
            return response;
        } catch (error) {
            throw error;
        }
    }
    
    const handleVideoUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        selectedImage &&
            setUploadPending(true);

        formData.append(
            "file",
            selectedImage,
            selectedImage.name
        );

        try {
            const options = {
                method: 'POST',
                body: formData
            };

            const response = thumbail && await fileUploadRequest(`/api/video/upload?reference=${state.ref}`, options);
            if (response.responseType == "Success") {
                setSuccess(true)
                setUploadPending(false)


                const route = `${BASE_URL}/api/pass/video/thumbnail/upload`

                const payload = {
                    videoId: response.data.videoId,
                    thumbnail: thumbail
                };

                await axios.post(route, payload, {
                    maxBodyLength: 20000000,
                });

                return response;

            }
        } catch (error) {
            throw error;
        }
    }

    const handleDelete = (url) => {
        setOpenModal(true)
        setDeleteImageUrl(url)
    }

    const toggle = () => setOpenModal(!openModal);

    const handleDeleteImage = async () => {
        const imageId = deleteImageUrl.split("/").pop()
        try {
            const options = {
                method: 'DELETE',
            };
            const response = await request(`/api/image/delete/${imageId}`, options);
            toggle();
            setAlert(true);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const handleDeleteVideo = async () => {
        const videoId = deleteImageUrl.split("/").pop()
        try {
            const options = {
                method: 'DELETE',
            };
            const response = await request(`/api/video/delete/${videoId}`, options);
            toggle();
            setAlert(true);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const handleAlert = () => { setAlert(!alert) }

    const handleSuccess = () => { setSuccess(!success) }

    useEffect(() => {
        async function fetchData() {
            let allUsers = await getImages()
            if (allUsers) {
                setImageList(allUsers?.data)
            }
        }

        async function fetchVideoData() {
            const allVideos = await getVideos()
            if (allVideos) {
                setImageList(allVideos?.data)
            }
        }
        if (isVideo) {
            fetchVideoData()
        } else {
            fetchData();
        }

    }, [alert, success, selectedLan]);

    useEffect(() => {
        async function fetchData() {
            let allLanguages = await getLanguages()
            if (allLanguages) {
                setLanguageList(allLanguages.data)
            }
        }
        fetchData()
    }, [])

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleLn = () => setDropdownOpen((prevState) => !prevState);

    const handleSelectedLan = (language) => {
        setSelectedLan(language)
    }
    return (
        <div>
            <Navigation />
            <div className='header-image'>
                <span>{state && state.posterName}</span>
            </div>

            <div className='image-button'>
                <div className='image-button__main-language-dropdown'>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleLn} direction="down">
                        <DropdownToggle caret>{selectedLan}</DropdownToggle>
                        <DropdownMenu container="body">
                            {languageList?.map((language) => (
                                <DropdownItem
                                    onClick={() => handleSelectedLan(language)}
                                >
                                    {language}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <input
                    type="file"
                    name="myImage"
                    onChange={handleChange}
                    accept={isVideo ? "video/*" : "image/*"}
                >
                </input>
                {uploadPending && <div><Spinner /></div>}
                <Button
                    size='lg'
                    onClick={(!isVideo) ? handleImageUpload : handleVideoUpload}
                    color='primary'
                    disabled={!selectedImage}
                >
                    Upload
                </Button>
            </div>
            {state.ref === "LICPlanCircular" && (
                    <div>
                        <input
                            type="file"
                            name="planCircularFile"
                            onChange={handleChange}
                            accept=".pdf"
                        />
                        {uploadPending && <div><Spinner /></div>}
                        <Button
                            size='lg'
                            onClick={handlePlanCircularUpload}
                            color='primary'
                            disabled={!planCircularFile}
                        >
                            Upload PlanCircular PDF
                        </Button>
                    </div>
                )}
           
            
            {state.ref == "PlanFlyers" ?
                <PlanFlyers
                    imageList={imageList}
                    baseUrl={baseUrl}
                    callback={handleDelete}
                    success={success}
                    setSuccess={setSuccess}
                    selectedLan={selectedLan}
                />
                :
                <div className='imagelist'>
                    {imageList && imageList?.length ?
                        imageList?.map(({ imageURL, videoURL }, index) => (
                            <div className='imagelist__wrap'>
                                <div className='imagelist__wrap__img'>
                                    <div>{index + 1}.</div>
                                    {!isVideo ?
                                        <img src={`${baseUrl}${imageURL}`} height={"60px"} width={"60px"} /> :
                                        <div>
                                            <video width="750" height="250" controls >
                                                <source src={`${baseUrl}${videoURL}`} type="video/mp4" />
                                            </video>
                                        </div>
                                    }
                                </div>
                                <div className="imagelist__wrap__delete">
                                    <div onClick={() => handleDelete(imageURL)}>
                                        <img src={TrashIcon} width={20} height={20} />
                                    </div>
                                </div>
                            </div>
                        )) :
                        <div className='imagelist__spinner'>
                            <Spinner />
                        </div>
                    }
                </div>}
            <Modal isOpen={openModal} toggle={toggle} >
                <ModalHeader toggle={toggle}>{`Delete ${isVideo ? "Video" : "Image"}`}</ModalHeader>
                <ModalBody>
                    {`Selected ${isVideo ? "Video" : "Image"} will be permantly deleted from the database. Are you Sure?`}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        onClick={!isVideo ? handleDeleteImage : handleDeleteVideo}
                    >
                        Yes
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal toggle={handleAlert} isOpen={alert}>
                <ModalHeader toggle={handleAlert}>{`Delete ${isVideo ? "Video" : "Image"}`}</ModalHeader>
                <ModalBody>
                    {`${isVideo ? "Video" : "File"} has been deleted successfully.`}
                </ModalBody>
            </Modal>
            <Modal toggle={handleSuccess} isOpen={success}>
                <ModalHeader toggle={handleSuccess}>{`Upload ${isVideo ? "Video" : "Image"}`}</ModalHeader>
                <ModalBody>
                    {`${isVideo ? "Video" : "File"} has been uploaded successfully.`}
                </ModalBody>
            </Modal>
        </div>
    );
}
export default ImageList;
