import { useNavigate } from "react-router-dom";

const ImageUpload = ({ type, image }) => {
    const ref = type.reference;
    const posterName = type.name;
    const video = type.video;
    const navigate = useNavigate();

    const handleOpenList = () => {
        navigate("/images/images-list", {
            state: {
                ref: ref,
                posterName: posterName,
                isVideo:video
            }
        });
    }

    return (
        <div>
            <div className="images__section__box" onClick={handleOpenList}>
                <div className="images__section__box__folder">
                    <img
                        src={image}
                        alt={ref}
                        width={"80px"}
                        height={"80px"}
                    >
                    </img>
                </div>
            </div>
            <div className="images__section__box__name">
                {type.name}
            </div>
        </div>
    );
};
export default ImageUpload;
