import TrashIcon from "./trashIcon.svg";
import { Spinner, Table } from 'reactstrap';
import UploadIcon from "./uploadIcon.svg";
import PdfIcon from "./pdfIcon.svg";
import PPTIcon from "./powerpointIcon.svg";
import React, { useEffect, useState } from "react";
import { fileUploadRequest } from "../api/request";
import ispdf from "./ispdf.svg";
import isPPTIcon from "./powerpointIcon.svg";

export const PlanFlyers = ({ imageList, baseUrl, callback, setSuccess, selectedLan }) => {

    const [isUploadPending, setUploadPending] = useState(null);
    const [currentImageId, setCurrentImage] = useState(null)
    const [isUploadPendingPPT, setUploadPendingPPT] = useState(null); // New state for PowerPoint upload
    const [currentImageIdPPT, setCurrentImagePPT] = useState(null); // New state for tracking current image id for PowerPoint

    const hiddenFileInput = React.useRef(null);
    const hiddenFilePDFInput = React.useRef(null);

    const handleClick = (imageURL) => {
        const imageId = imageURL.split("/").pop()
        setCurrentImage(imageId)
        console.log('imageId', imageId);
        hiddenFilePDFInput.current.click();
    };
    const handleClickPPT = (imageURL) => {
        const imageId = imageURL.split("/").pop();
        console.log('imageId', imageId);
        setCurrentImagePPT(imageId);
        hiddenFileInput.current.click();
    };

    const handlePPTUpload = async (e) => {
        const selectedPPT = e.target.files[0];
        const formData = new FormData();
        const imageId = currentImageIdPPT
        formData.append(
            "ppt",
            selectedPPT,
            selectedPPT.name
        );

        try {
            const options = {
                method: 'POST',
                body: formData
            };
            console.log('currentImageId', imageId);
            const response = await fileUploadRequest(`/api/image/ppt/uploadPPT?reference=PlanFlyers&imageId=${imageId}&language=${selectedLan}&pdfReference=Presentation`, options);
            setUploadPendingPPT(true);
            if (response.responseType === "Success") {
                setSuccess(true);
                setUploadPendingPPT(false);
            }
            return response;
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        // ... existing code ...
    }, [isUploadPendingPPT]);

    const handlePdfUpload = async (e) => {

        const selectedPdf = e.target.files[0];
        const formData = new FormData();

        formData.append(
            "ppt",
            selectedPdf,
            selectedPdf.name
        );

        try {
            const options = {
                method: 'POST',
                body: formData
            };
            console.log('currentImageId', currentImageId);
            const response = await fileUploadRequest(`/api/image/ppt/uploadPPT?reference=PlanFlyers&imageId=${currentImageId}&language=${selectedLan}&pdfReference=Plan`, options);
            setUploadPending(true)
            if (response.responseType == "Success") {
                setSuccess(true)
                setUploadPending(false)
            }
            return response;
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {

    }, [isUploadPending])

    return (
        <div className="imagelist">
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Corresponding PDF</th>
                        <th>PowerPoint Presentation</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {imageList && imageList?.length ?
                        imageList.map(({ imageURL, pdfURL, pptURL }, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}.</th>
                                <td>
                                    <div>
                                        <img src={`${baseUrl}${imageURL}`} height={"60px"} width={"60px"} alt={`Image ${index + 1}`} />
                                    </div>
                                </td>
                                <td>
                                    <div className="imagelist__planflyers">
                                        {pdfURL ? (
                                            <div>
                                                <a href={`${baseUrl}${pdfURL}`}>
                                                    <img src={ispdf} height={"30px"} width={"30px"} alt="PDF" />
                                                </a>
                                            </div>
                                        ) : (
                                            <div>
                                                <img src={PdfIcon} height={"30px"} width={"30px"} alt="PDF Icon" />
                                            </div>
                                        )}
                                        {isUploadPending && <div>
                                            <Spinner />
                                        </div>}
                                        <div>
                                            <img
                                                src={UploadIcon}
                                                height={"20px"}
                                                width={"20px"}
                                                onClick={() => handleClick(imageURL)}
                                                className="imagelist__planflyers__upload"
                                            />
                                            <input
                                                type="file"
                                                ref={hiddenFilePDFInput}
                                                onChange={handlePdfUpload}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="imagelist__planflyers">
                                        {pptURL ? (
                                            <div>
                                                <a href={`${baseUrl}${pptURL}`}>
                                                    <img src={isPPTIcon} height={"30px"} width={"30px"} alt="PPT" />
                                                </a>
                                            </div>
                                        ) : (
                                            <div>
                                                <img src={PPTIcon} height={"30px"} width={"30px"} alt="PPT Icon" />
                                            </div>
                                        )}
                                        {isUploadPendingPPT && <div>
                                            <Spinner />
                                        </div>}
                                        <div>
                                            <img
                                                src={UploadIcon}
                                                height={"20px"}
                                                width={"20px"}
                                                onClick={() => handleClickPPT(imageURL)}
                                                className="imagelist__planflyers__upload"
                                            />
                                            <input
                                                type="file"
                                                ref={hiddenFileInput}
                                                onChange={handlePPTUpload}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td onClick={() => callback(imageURL)}>
                                    <img src={TrashIcon} width={20} height={20} alt="Delete" />
                                </td>
                            </tr>
                        )) :
                        <div className='imagelist__spinner'>
                            <Spinner />
                        </div>
                    }
                </tbody>
            </Table>
        </div>
    );
};
