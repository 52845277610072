import Navigation from "../nav";
import Types from "./types.json";
import "./images.scss";
import image from "./folder.svg";
import ImageUpload from "./imageUpload";

const Images = () => {

    return (
        <div>
            <Navigation />
            <div className="images">
                <div className="images__header">
                    <h1>Upload/ Manage Images</h1>
                    <div className="images__section">
                        {
                            Types.map((type, index) => (
                                <ImageUpload
                                    type={type}
                                    image={image}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Images;