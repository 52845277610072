// export const BASE_URL = "https://iioe.scrapegps.buzz:8443/iioe-be";
// export const BASE_URL = "https://fierce-caverns-97222.herokuapp.com";
// export const BASE_URL = "http://iioe.scrapegps.buzz:8080/iioe-be";
// export const BASE_URL = "https://iioe.scrapegps.buzz:8443/iioe-be";
export const BASE_URL = "https://iioeapi.digipine.in:8443/iioe-be";



export class ResponseError extends Error {
    response;

    constructor(response) {
        super(response.statusText);
        this.response = response;
    }
}

function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    return response.json();
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new ResponseError(response);
    error.response = response;
    throw error;
}

export const request = async(
    url,
    options,
    token
) => {
    const authToken = token || localStorage.getItem("token");
    const fetchResponse = await fetch(`${BASE_URL}${url}`, {
        ...options,
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    });
    const response = await checkStatus(fetchResponse);
    return parseJSON(response);
};

export const fileUploadRequest= async(
    url,
    options,
    token
) => {
    const authToken = token || localStorage.getItem("token");
    const fetchResponse = await fetch(`${BASE_URL}${url}`, {
        ...options,
        headers: {
            'Authorization': `Bearer ${authToken}`,
        },
    });

    const response = await checkStatus(fetchResponse);
    return parseJSON(response);
};


